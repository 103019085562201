import { breakpoints } from '@configs/breakpoints';
import { ContentText, px2rem } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  row-gap: ${px2rem(32)};
  align-self: center;
  justify-content: center;
  max-width: ${px2rem(1280)};
  margin: auto;
  align-items: center;

  @media screen and (max-width: ${breakpoints.mobile}) {
    margin: ${px2rem(24)};
  }
`;

export const Header = styled(ContentText)`
  font-size: ${px2rem(36)};

  @media screen and (max-width: ${breakpoints.mobile}) {
    font-size: ${px2rem(29)};
    line-height: 100%;
  }
`;

export const DescriptionsContainer = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: ${px2rem(4)};
  align-self: center;
`;
export const Description = styled(ContentText)`
  white-space: pre-line;
  line-height: ${px2rem(35)};
  text-align: center;
`;

export const DetailedDescriptionContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  column-gap: ${px2rem(4)};
`;
