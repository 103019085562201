import { breakpoints as ElementalBreakpoints } from '@fortum/elemental-ui';

export const breakpoints = {
  xsMobile: `${ElementalBreakpoints.xs}px`,
  mobile: `${ElementalBreakpoints.s}px`,
  medium: `${ElementalBreakpoints.m}px`,
  large: `${ElementalBreakpoints.l}px`,
  desktop: `${ElementalBreakpoints.xl}px`,
};

export const mobileWidthPattern = `screen and (max-width: ${breakpoints.mobile})`;
