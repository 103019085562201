import { isCustomerType, RawUserInfo, UserInfo } from './userInfo';

export const toUserInfo = (rawData: RawUserInfo): UserInfo => {
  const {
    customerType,
    street,
    postalCode,
    city,
    emailContractCommunication,
    emailCustomerCommunication,
    emailNewsletter,
    emailEvents,
    emailSurveys,
    customerDeliveryPoints,
    ownerAdminCustomers,
    ...rest
  } = rawData;

  return {
    ...rest,
    customerType: isCustomerType(customerType) ? customerType : null,
    address: {
      street,
      postalCode,
      city,
    },
    marketingPreferences: {
      'EMAIL.CONTRACT': emailContractCommunication,
      'EMAIL.MARKETING': emailCustomerCommunication,
      'EMAIL.NEWSLETTER': emailNewsletter,
      'EMAIL.EVENTS': emailEvents,
      'EMAIL.SURVEYS': emailSurveys,
    },
    customerDeliveryPoints,
    ownerAdminCustomers: ownerAdminCustomers || {
      owners: [],
      representatives: [],
    },
  };
};

export const toRawUserInfo = (userInfo: UserInfo): RawUserInfo => {
  const {
    address,
    marketingPreferences,
    customerType,
    customerNumber,
    customerDeliveryPoints,
    ownerAdminCustomers,
    ...data
  } = userInfo;

  return {
    ...data,
    ...address,
    customerType: customerType ?? '',
    customerNumber: customerNumber ?? '',
    emailContractCommunication: marketingPreferences['EMAIL.CONTRACT'],
    emailCustomerCommunication: marketingPreferences['EMAIL.MARKETING'],
    emailNewsletter: marketingPreferences['EMAIL.NEWSLETTER'],
    emailEvents: marketingPreferences['EMAIL.EVENTS'],
    emailSurveys: marketingPreferences['EMAIL.SURVEYS'],
    customerDeliveryPoints,
    ownerAdminCustomers,
  };
};
