import React, { useEffect, useState, useMemo } from 'react';
import { Sidebar, FortumLogo, SidebarItem } from '@fortum/elemental-ui';
import { useNavigate, useLocation } from 'react-router-dom';
import { subRoutes } from '@routers/routes';
import { Namespace, useTranslation } from 'react-i18next';
import { getPath } from '@routers/utils';
import { useUserInfo } from '@api/user/useUserInfo';
import {
  CloseIconButton,
  HeaderSidebar,
  HeaderTextMobile,
  SidebarGroup,
  SidebarLink,
} from './styles';

interface AppSidebarProps {
  visible: boolean;
  onHide: () => void;
  text: string;
}

export const AppSidebar: React.FC<AppSidebarProps> = ({
  visible,
  onHide,
  text,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation<Namespace>('glossary');
  const { data: userInfo } = useUserInfo();

  const isRepresentative = userInfo?.isRepresentative ?? false;
  const customerType = userInfo?.customerType;
  const hideManageCustomers = !isRepresentative || customerType === 'B2C';

  const groups = [
    { label: t('page.name.consumption'), path: subRoutes.consumption },
    { label: t('page.name.deliveryPoints'), path: subRoutes.consumptionSites },
    { label: t('page.name.invoices'), path: subRoutes.invoices },
    { label: t('page.name.contactUs'), path: subRoutes.contactUs },
  ];

  const reportTabs = [
    {
      label: t('reportTabs.forecast.forecastTab'),
      path: `${subRoutes.reports}?tab=forecast`,
      tab: 'forecast',
    },
    {
      label: t('reportTabs.consumption.consumptionTab'),
      path: `${subRoutes.reports}?tab=consumption`,
      tab: 'consumption',
    },
    {
      label: t('reportTabs.tableTab'),
      path: `${subRoutes.reports}?tab=consumptionTable`,
      tab: 'consumptionTable',
    },
    {
      label: t('reportTabs.archiveTab'),
      path: `${subRoutes.reports}?tab=archive`,
      tab: 'archive',
    },
  ];

  const ownInformationTabs = useMemo(
    () => [
      {
        label: t('ownInforamtion.tabs.ownInforamtion'),
        path: `${subRoutes.ownInformation}?tab=ownInformation`,
        tab: 'ownInformation',
      },
      {
        label: t('ownInforamtion.tabs.preferences'),
        path: `${subRoutes.ownInformation}?tab=communicationPreferences`,
        tab: 'communicationPreferences',
      },
      ...(!hideManageCustomers
        ? [
            {
              label: t('ownInforamtion.tabs.manageCustomers'),
              path: `${subRoutes.ownInformation}?tab=manageCustomers`,
              tab: 'manageCustomers',
            },
          ]
        : []),
      ...(!isRepresentative
        ? [
            {
              label: t('ownInforamtion.tabs.ownerView'),
              path: `${subRoutes.ownInformation}?tab=ownerView`,
              tab: 'ownerView',
            },
          ]
        : []),
    ],
    [t, hideManageCustomers, isRepresentative],
  );

  const [activeTab, setActiveTab] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setActiveTab(params.get('tab'));
  }, [location]);

  const handleItemClick = (path: string) => {
    navigate(getPath(path));
    onHide();
  };

  const handleTabClick = (path: string, tab: string) => {
    setActiveTab(tab);
    navigate(path);
    onHide();
  };

  const isActive = (path: string) => location.pathname === `/${path}`;

  return (
    <Sidebar
      mode="floating"
      headerContent={
        <>
          <HeaderSidebar>
            <a href="https://www.fortum.fi">
              <FortumLogo size="s" />
            </a>
            <CloseIconButton onClick={onHide} />
          </HeaderSidebar>
          <HeaderTextMobile>{text}</HeaderTextMobile>
        </>
      }
      visible={visible}
      onHide={onHide}
    >
      {groups.slice(0, 2).map(group => (
        <SidebarLink
          key={group.label}
          tag="a"
          isActive={isActive(group.path)}
          onClick={() => handleItemClick(group.path)}
        >
          {group.label}
        </SidebarLink>
      ))}

      <SidebarGroup label={t('page.name.reports')}>
        {reportTabs.map(tab => (
          <SidebarItem
            key={tab.label}
            tag="a"
            isActive={activeTab === tab.tab}
            onClick={event => {
              event.preventDefault();
              handleTabClick(tab.path, tab.tab);
            }}
          >
            {tab.label}
          </SidebarItem>
        ))}
      </SidebarGroup>

      {groups.slice(2, 3).map(group => (
        <SidebarLink
          key={group.label}
          tag="a"
          isActive={isActive(group.path)}
          onClick={() => handleItemClick(group.path)}
        >
          {group.label}
        </SidebarLink>
      ))}

      <SidebarGroup label={t('page.name.ownInformation')}>
        {ownInformationTabs.map(tab => (
          <SidebarItem
            key={tab.label}
            tag="a"
            isActive={activeTab === tab.tab}
            onClick={event => {
              event.preventDefault();
              handleTabClick(tab.path, tab.tab);
            }}
          >
            {tab.label}
          </SidebarItem>
        ))}
      </SidebarGroup>

      {groups.slice(3).map(group => (
        <SidebarLink
          key={group.label}
          tag="a"
          isActive={isActive(group.path)}
          onClick={() => handleItemClick(group.path)}
        >
          {group.label}
        </SidebarLink>
      ))}
    </Sidebar>
  );
};
