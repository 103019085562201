import { subRoutesValues, SubRouteValue } from './routes';

const isSupportedSubRoute = (route: string): route is SubRouteValue =>
  subRoutesValues.includes(route as SubRouteValue);

export const getPath = (subRoute: string, tab?: string) => {
  const basePath = `/${subRoute}`;
  return tab ? `${basePath}?tab=${tab}` : basePath;
};

export const getSubRouteName = (
  pathname: string,
): SubRouteValue | undefined => {
  const searchedSubRoute = pathname.substring(1);

  return isSupportedSubRoute(searchedSubRoute) ? searchedSubRoute : undefined;
};
